import * as React from "react"
const SvgComponent = (props) => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="1.699 0.146 28.025 28.024"
    {...props}
  >
    <path fill="currentColor" d="M15.719.189c-7.72 0-14 6.28-14 14 0 7.719 6.28 14 14 14 7.719 0 14-6.281 14-14 0-7.72-6.281-14-14-14Zm.25 23a8.928 8.928 0 0 1-6.43-2.709 1.124 1.124 0 1 1 1.608-1.573 6.699 6.699 0 0 0 4.822 2.032 6.758 6.758 0 0 0 6.75-6.75 6.757 6.757 0 0 0-6.75-6.75c-3.078 0-5.676 2.072-6.485 4.893l.064-.064a1.126 1.126 0 0 1 1.591 1.591l-2.25 2.25a1.122 1.122 0 0 1-1.591 0l-2.25-2.25a1.126 1.126 0 0 1 1.591-1.591l.459.459c.702-4.268 4.407-7.538 8.871-7.538 4.962 0 9 4.037 9 9 0 4.962-4.038 9-9 9Zm2.457-7.707a1 1 0 0 1-1.414 1.414l-2-2a1 1 0 0 1-.293-.707v-4a1 1 0 1 1 2 0v3.585l1.707 1.708Z" />
  </svg>
)
export default SvgComponent
