
import { styled } from 'styled-components';
import { UserCard } from './UserCard/UserCard';
import { Space } from '../components/Space';
import { Results } from './Results/Results';
import { $isScrolled } from '../models/appState';
import { useUnit } from 'effector-react';

const HeaderContainer = styled.div`
    margin: 0 -12px;
    box-sizing: border-box;
    width: 100vw;
    box-shadow: ${props => props.isScrolled ? "0px 3px 8px #00000068" : "none"};
    padding: 12px;
    padding-bottom: 0;
    position: sticky;
    top: 0;
    background-color: var(--chakra-colors-chakra-body-bg);
    z-index: 1;
`;

export const PageHeader = () => {
    const [isScrolled] = useUnit([
        $isScrolled
    ])

    return (
        <HeaderContainer isScrolled={isScrolled}>
            <UserCard />
            <Space />
            <Results betsCount={123} betsWonPersentage={88} averageCoef={1.56} showDivider={false} />
        </HeaderContainer>
    )
}