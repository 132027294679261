import { Box, List, ListItem } from "@chakra-ui/react"
import { BetCard } from "./BetCard"

export const BetsHistory = ({
    bets
}) => {
    return (
        <Box boxSizing="border-box">
            <List spacing={3} boxSizing="border-box">
                {bets.map((bet) => (
                    <ListItem key={bet._id}>
                        <BetCard
                            flag={bet.flag}
                            date={bet.date}
                            teamA={bet.teamA}
                            teamB={bet.teamB}
                            betDescription={bet.betDescription}
                            betCoef={bet.betCoef}
                            status={bet.status}
                        />
                    </ListItem>
                ))}
            </List>
        </Box >
    )
}