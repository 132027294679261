import { createEvent } from "effector";

export const setTgUserData = createEvent();
export const initializeApp = createEvent();
export const matchShowed = createEvent();
export const setUserPremium = createEvent();

export const signIn = createEvent();
export const signUp = createEvent();


