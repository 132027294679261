import React from "react";

import { Badge, Box, Divider, Flex, Heading, Image, Spacer, Text } from "@chakra-ui/react"
import styled from "styled-components";
import { flags } from "../flags";
import { CustomCard } from '../components/Card';
import dayjs from "dayjs";
import { declOfNum } from '../utils/numbers';

const BetLogo = styled.div`
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: white;
`

const Logo = ({ src }) => {
    return (
        <BetLogo>
            <Image
                src={src}
                height="25px"
                width="25px"
            />
        </BetLogo>
    )
}

const BetTeamsHeader = styled.div`
    margin-left: 16px;
    font-size: var(--font-small);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const TeamName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const MatchContainer = styled.div`
    display: grid;
    grid-template-columns: 25px 2fr 3fr 90px;
    align-items: center;
`

const CustomBadge = styled.div`
    background-color: var(--color-dark-secondary);
    color: var(--chakra-colors-chakra-blue-600);
    border-radius: 6px;
    padding: 4px 8px;
    font-weight: 900;
    font-size: var(--font-small);
    width: 100%;
    text-align: center;
`

export const Match = React.memo(({
    date,
    teamA,
    teamB,
    countOfPredictions,
    flag,
}) => {
    const formatedDate = dayjs(date).format("DD.MM.YYYY HH:mm");
    const countOfPredictionsTitle = declOfNum(countOfPredictions, ['прогноз', 'прогноза', 'прогнозов']);
    return (
        <CustomCard>
            <MatchContainer>
                <Logo src={flags[flag]} />
                <BetTeamsHeader>
                    <TeamName>{teamA}</TeamName>
                    <TeamName>{teamB}</TeamName>
                </BetTeamsHeader>
                <Text size="12px" fontSize={12} textAlign="right" mr="16px">{formatedDate}{'\u00A0'}MSK</Text>
                { countOfPredictions > 0 && <CustomBadge>{countOfPredictions} {countOfPredictionsTitle}</CustomBadge>}
            </MatchContainer>
        </CustomCard>
    )
})