import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react'
import { useStore, useUnit } from 'effector-react'
import { $userData } from '../models/user'
import { MAX_FREE_MATCHES } from '../models/user/constants'

export function AproveModal({isOpen, onContinue, onClose}) {
    const [userData] = useUnit([$userData])
    const remainingMatches = MAX_FREE_MATCHES - (userData.openedMatches?.length || 0)

    return (
        <Modal
            size="xs"
            motionPreset='slideInBottom'
            blockScrollOnMount={false}
            isOpen={isOpen}
            onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader fontSize="14px">Просмотр прогнозов</ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody>
                    <Text mb='1rem' fontSize="14px">
                        На текущем тарифе Вам доступен просмотр ещё {remainingMatches} {getRussianWordForm(remainingMatches, ['матча', 'матчей', 'матчей'])}. Хотите посмотреть данный матч?
                    </Text>
                </ModalBody>

                <ModalFooter>
                    <Button fontSize="14px" colorScheme='blue' mr={3} onClick={onContinue}>
                        Продолжить
                    </Button>
                    <Button fontSize="14px" variant='ghost' onClick={onClose}>Назад</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

function getRussianWordForm(number, words) {
    const cases = [2, 0, 1, 1, 1, 2]
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
}
