import * as React from "react"
const PremiumIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 52 52",
    }}
    viewBox="0 0 52 65"
    fill="currentColor"
    {...props}
  >
    <path fill="currentColor"
      d="M38.94 39.535H13.38l-1.14 4.58c-.08.3-.01.62.18.86s.48.39.79.39H39.13c.55 0 1-.45 1-1 0-.16-.03-.3-.1-.43l-1.09-4.4zM46.53 13.995c-1.91 0-3.46 1.55-3.46 3.46 0 .639.185 1.23.487 1.746l-7.217 6.164-1.342-11.122a3.452 3.452 0 0 0 2.942-3.408 3.461 3.461 0 0 0-6.92 0 3.45 3.45 0 0 0 1.467 2.82l-6.327 10.26-6.707-10.891a3.45 3.45 0 0 0 1.637-2.929 3.461 3.461 0 0 0-6.92 0 3.451 3.451 0 0 0 3.238 3.438L15.98 25.365l-7.636-6.521A3.461 3.461 0 1 0 2 16.935a3.47 3.47 0 0 0 3.46 3.47c.428 0 .834-.089 1.213-.232l6.447 17.362h26.09l6.23-16.803c.345.115.707.193 1.09.193 1.91 0 3.47-1.56 3.47-3.47a3.47 3.47 0 0 0-3.47-3.46z" />
  </svg>
)
export default PremiumIcon
