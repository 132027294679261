import * as React from "react"
const SvgComponent = (props) => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 31.492 31.492"
    {...props}
  >
    <path fill="currentColor" d="M15.796.049c-.017 0-.033.002-.05.003-.017 0-.033-.003-.05-.003C7.028.049 0 7.076 0 15.745s7.028 15.698 15.696 15.698c.017 0 .033-.002.05-.004.017 0 .033.004.05.004 8.668 0 15.696-7.028 15.696-15.697S24.464.049 15.796.049zm1.03 4.556 4.087-.47a12.731 12.731 0 0 1 4.069 2.871l.521 4.164-5.051 1.327-3.627-2.525V4.605zM6.509 7.006a12.746 12.746 0 0 1 4.07-2.871l4.087.47v5.367l-3.627 2.525-5.051-1.327.521-4.164zM4.594 21.889a12.607 12.607 0 0 1-1.55-5.267l2.155-3.593 5.116 1.344 1.294 4.27-3.331 4.965-3.684-1.719zm14.056 6.218c-.92.212-1.872.334-2.854.336l-.05-.002-.05.002a12.78 12.78 0 0 1-2.854-.336l-2.885-3.411 3.254-4.847h5.07l3.254 4.847-2.885 3.411zm4.564-4.5-3.331-4.965 1.295-4.27 5.115-1.344 2.155 3.593a12.626 12.626 0 0 1-1.55 5.267l-3.684 1.719z" />
  </svg>
)
export default SvgComponent
