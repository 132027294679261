import { Page } from "../../components/Page"
import { Premium } from "../../widgets/Premium/Premium";
import { UserCard } from '../../widgets/UserCard/UserCard';
import { Box } from '@chakra-ui/react';
import { Space } from "../../components/Space";
import { CustomCard } from "../../components/Card";
import { useUnit } from "effector-react";
import { emitEvent, EVENTS } from "../../models/statistics";

export const ProfilePage = () => {
    const [emitEventFn] = useUnit([emitEvent]);
    const handlePremiumClick = () => {
        emitEventFn(EVENTS.profilePremiumClick);
    }
    return (
        <Page>
            <Box paddingTop="12px">
                <UserCard />
            </Box>
            <Space />
            <CustomCard>
                <Premium onPremium={handlePremiumClick} />
            </CustomCard>
        </Page>
    )
}