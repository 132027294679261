import { Match } from '../../components/Match';
import { useQuery } from 'react-query';
import { config } from '../../config';
import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';
import { ScrollablePageContent } from '../../components/Page';

const ScrollableList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 16px;
    overflow-y: auto;
    scrollbar-width: none;  /* Для Firefox */
    -ms-overflow-style: none;  /* Для Internet Explorer и Edge */
    &::-webkit-scrollbar {
        display: none;  /* Для Chrome, Safari и Opera */
    }
`;

export const MatchesList = ({ onMatchClick }) => {
    const { data: matches, isLoading } = useQuery('matches', async () => {
        const response = await fetch(`${config.apiUrl}/matches`);
        const data = await response.json();
        return data
    });
    return (
        <ScrollablePageContent>
            {isLoading && <Flex width="100%" height="100%" justifyContent="center" alignItems="center">Загрузка...</Flex>}
            <ScrollableList>
                {matches && matches.length > 0 ? (
                    matches.map((match) => (
                        <div key={match._id} onClick={() => onMatchClick(match._id)}>
                            <Match
                                date={match.date}
                                teamA={match.teamA}
                                teamB={match.teamB}
                                countOfPredictions={match.bets.length}
                                flag={match.flag}
                            />
                        </div>
                    ))
                ) : (
                    <Flex width="100%" justifyContent="center" alignItems="center">
                        Нет активных прогнозов
                    </Flex>
                )}
            </ScrollableList>
        </ScrollablePageContent>
    )
}
