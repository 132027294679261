import React from "react";

import { Badge, Box, Divider, Flex, Heading, Image, Spacer, Text } from "@chakra-ui/react"
import styled from "styled-components";
import { flags } from "../../flags";
import dayjs from "dayjs";
import { CustomCard } from '../../components/Card';

const BetLogo = styled.div`
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: white;
`

const Logo = ({ src }) => {
    return (
        <BetLogo>
            <Image
                src={src}
                height="20px"
                width="20px"
            />
        </BetLogo>
    )
}

export const Match = React.memo(({
    date,
    teamA,
    teamB,
    flag,
    league
}) => {
    const formatedDate = dayjs(date).format("DD.MM.YYYY");
    const formatedTime = dayjs(date).format("HH:mm");
    return (
        <CustomCard>
            <Flex justifyContent="space-between" alignItems="center">
                <Box textAlign="center" width="30%" overflow="hidden">
                    <Text isTruncated>{teamA}</Text>
                    <Text fontSize="sm">(хозяева)</Text>
                </Box>
                <Box textAlign="center" width="30%">
                    <Flex alignItems="center" justifyContent="center" gap='8px'>
                        <Logo src={flags[flag]} />
                        <Text isTruncated>{league}</Text>
                    </Flex>
                    <Text size="12px" fontSize={12}>{formatedDate}<br />{formatedTime}{'\u00A0'}MSK</Text>
                </Box>
                <Box textAlign="center" width="30%" overflow="hidden">
                    <Text isTruncated>{teamB}</Text>
                    <Text fontSize="sm">(гости)</Text>
                </Box>
            </Flex>
        </CustomCard>
    )
})