import { createEffect, createEvent, sample } from "effector";

export const EVENTS = {
    successSignUp: 'success-sign-up',
    successSignIn: 'success-sign-in',
    showPremiumPopup: 'show-premium-popup',
    navigationClick: 'navigation-click',
    matchClick: 'match-click',
    profilePremiumClick: 'profile-premium-click',
    premiumPopupClick: 'premium-popup-click',
    premiumDetailsClick: 'premium-details-click'
}
export const emitEvent = createEvent();

const sendEventFx = createEffect((eventName) => {
    console.log('sendEventFx')
    if (typeof window.ym === 'function') {
        console.log('reachGoal', eventName)
        window.ym(98640074, 'reachGoal', eventName)
    }
})
sample({
    clock: emitEvent,
    target: sendEventFx
})