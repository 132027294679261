import { Box, Button, Flex } from "@chakra-ui/react"
import styled from "styled-components"
import AvgCoef from "../../icons/avgCoef"
import LotsLeagues from "../../icons/aLotLegues"
import style from './style.module.css';
import { CustomCard } from "../../components/Card";
import ArgumentsMatch from "../../icons/argumentsMatch";
import { BellIcon } from "@chakra-ui/icons";
import Notifications from "../../icons/notifications";
import NoAds from '../../icons/noAds';
import InfinityIcon from "../../icons/infinity";
import PremiumIcon from "../../icons/premium";
import { useNavigate } from "react-router-dom";
import { useUnit } from 'effector-react';
import { $isUserPremium, $isTelegramApp } from '../../models/user';

const Title = styled.h3`
    font-size: 16px;
    font-weight: bold;
    color: var(--color-secondary);
    text-align: center;
    margin-bottom: 16px;
`
const Text = styled.span``


const Item = ({ children, text }) => {

    return (
        <Flex gap="8px">
            {children} <Text>{text}</Text>
        </Flex>

    )
}

export const Premium = ({onPremium}) => {
    const navigate = useNavigate();
    const [isUserPremium, isTelegramApp] = useUnit([$isUserPremium, $isTelegramApp]);
    console.log({isUserPremium, isTelegramApp})

    const handlePremiumClick = () => {
        onPremium();
        if (isTelegramApp) {
            navigate('/subscribe-success');
        } else {
            navigate('/signup');
        }
    };

    return (
        <Flex flexDir="column" alignItems="center">
            <Box>
                <PremiumIcon className={style.premium} />
            </Box>
            <Title>WISEBETS PREMIUM</Title>
            <Flex px="10%" fontSize="13px" flexDir="column" gap="8px">
                <Item text="Неограниченное количество прогнозов">
                    <InfinityIcon className={style.infIcon} />
                </Item>
                <Item text="Большое количество футбольных лиг">
                    <LotsLeagues className={style.icon} />
                </Item>
                <Item text="Подробная аргуметнация матчей">
                    <ArgumentsMatch className={style.icon} />
                </Item>
                <Item text="Моментальные уведомления о прогнозах">
                    <Notifications className={style.icon} />
                </Item>
                <Item text="Никакой рекламы">
                    <NoAds className={style.icon} />
                </Item>
            </Flex>
            <Box textAlign="center" mt="24px">
                {isUserPremium ? (
                    <Text>Вы уже являетесь премиум пользователем</Text>
                ) : (
                    <>
                        <Button textTransform="uppercase" colorScheme='blue' size='xs' onClick={handlePremiumClick}>Оформить подписку</Button>
                        <Box fontSize="11px">999 рублей в месяц</Box>
                    </>
                )}
            </Box>
        </Flex>
    )
}
