import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={800}
    height={800}
    viewBox="0 0 296.906 296.906"
    fill="currentColor"
    {...props}
  >
    <path d="M65.486 185.302c-.185-.184-.362-.374-.546-.559l-23.798 83.136c-1.514 5.288 1.651 8.685 7.032 7.549l29.481-6.222a13.18 13.18 0 0 1 1.208-.19l17.442-61.512c-11.262-5.599-21.664-13.048-30.819-22.202zM93.313 273.043c.455.347.89.706 1.278 1.086l20.992 20.518c3.934 3.844 8.39 2.663 9.903-2.625l20.719-72.381c-12.417-.231-24.516-2.384-35.959-6.313l-16.933 59.715zM150.7 219.641l20.719 72.381c1.514 5.288 5.97 6.469 9.903 2.625l20.992-20.518c.35-.342.737-.666 1.142-.98l-16.812-59.815c-11.438 3.924-23.532 6.075-35.944 6.307zM231.966 184.742c-.184.186-.361.375-.546.559-9.156 9.156-19.563 16.607-30.83 22.206L217.872 269c.471.049.935.111 1.378.205l29.481 6.222c5.381 1.136 8.546-2.261 7.032-7.549l-23.797-83.136zM250.786 102.334C250.786 45.816 204.97 0 148.452 0 91.936 0 46.12 45.816 46.12 102.334s45.816 102.334 102.332 102.334c56.518 0 102.334-45.816 102.334-102.334zm-102.334 81.627c-45.008 0-81.625-36.617-81.625-81.627s36.617-81.627 81.625-81.627c45.01 0 81.627 36.617 81.627 81.627s-36.617 81.627-81.627 81.627z" />
    <path d="M148.452 168.961c36.738 0 66.627-29.889 66.627-66.627s-29.889-66.627-66.627-66.627c-36.736 0-66.625 29.889-66.625 66.627s29.889 66.627 66.625 66.627z" />
  </svg>
)
export default SvgComponent
