import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={800}
    height={800}
    viewBox="0 0 32 32"
    fill="currentColor"
    {...props}
  >
    <path d="M30 3H2c-.6 0-1 .4-1 1s.4 1 1 1h1v16c0 .6.4 1 1 1h9.8l-5.5 6.3c-.4.4-.3 1 .1 1.4.1.2.4.3.6.3.3 0 .6-.1.8-.3l5.2-6V28c0 .6.4 1 1 1s1-.4 1-1v-4.3l5.2 6c.2.2.5.3.8.3.2 0 .5-.1.7-.2.4-.4.5-1 .1-1.4L18.2 22H28c.6 0 1-.4 1-1V5h1c.6 0 1-.4 1-1s-.4-1-1-1zm-5.2 5.6-4 6c-.3.4-.8.6-1.3.3L15.8 13h-3.4l-3.7 3.7c-.2.2-.4.3-.7.3s-.5-.1-.7-.3c-.4-.4-.4-1 0-1.4l4-4c.2-.2.4-.3.7-.3h4c.2 0 .3 0 .4.1l3.2 1.6 3.5-5.3c.3-.5.9-.6 1.4-.3.5.4.6 1 .3 1.5z" />
  </svg>
)
export default SvgComponent
