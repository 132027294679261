import { Tab, TabList, Tabs, TabPanels, TabPanel, TabIndicator } from "@chakra-ui/react"
import { CustomCard } from "./Card"
import { styled } from 'styled-components';
import FireIcon from '../icons/fire';

const TrendsContainer = styled.div`
    font-size: var(--font-small);
    padding: 0 16px;
    margin-top: -4px;
`;

const TrendTextContent = styled.div`
    padding: 0 8px;
`;

const Title = styled.h2`
    font-size: 12px;
    margin-bottom: 8px;
    color: var(--color-secondary);
    font-weight: 500;
    padding-left: 8px;
    padding-top: 4px;
    display: flex;

`;

const FireIconStyled = styled(FireIcon)`
    width: 16px;
    height: 16px;
    padding-right: 6px;
`;

const Trend = ({ trendString }) => {
    const trends = trendString.split(';').filter(trend => trend.length > 0);

    return (
        <ul>
            {trends.map((trend) => (
                <li>{trend}</li>
            ))}
        </ul>
    )
}

export const Trends = ({ trends }) => {
    return (
        <CustomCard title={<Title><FireIconStyled /> Ключевые тренды</Title>}>
            <TrendsContainer>
                <Tabs>
                    <TabList>
                        {trends.map((trend) => (
                            <Tab key={trend.title} fontSize="var(--font-small)">{trend.title}</Tab>
                        ))}
                    </TabList>
                    <TabPanels>
                        {
                            trends.map((trend) => (
                                <TabPanel padding="8px" paddingBottom="0">
                                    <TrendTextContent>
                                        <Trend trendString={trend.trend} />
                                    </TrendTextContent>
                                </TabPanel>
                            ))
                        }
                    </TabPanels>
                </Tabs>
            </TrendsContainer>
        </CustomCard>
    )
}