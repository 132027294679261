import * as React from "react"
const LotsLeagues = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 100 125"
    fill="currentColor"
    {...props}
  >
    <title>{"Artboard 5"}</title>
    <path d="M22.64 45.55h2.43A27.4 27.4 0 0 0 44.23 61v9h-6.71a3.75 3.75 0 0 0-3.75 3.75v4.92h-3.35A2.39 2.39 0 0 0 28 81.11V93.9a2.39 2.39 0 0 0 2.39 2.39h39.18A2.39 2.39 0 0 0 72 93.9V81.11a2.39 2.39 0 0 0-2.39-2.39h-3.38V73.8a3.75 3.75 0 0 0-3.75-3.75h-6.71V61a27.4 27.4 0 0 0 19.16-15.45h2.43a18.71 18.71 0 0 0 18.71-18.72V15.46a3 3 0 0 0-3-3H77.36V6.71a3 3 0 0 0-3-3H25.63a3 3 0 0 0-3 3v5.76H6.92a3 3 0 0 0-3 3v11.36a18.71 18.71 0 0 0 18.72 18.72Zm54.72-11.27V21.94h9.24v4.89a9.25 9.25 0 0 1-9.24 9.24h-.06c.03-.59.06-1.19.06-1.79ZM38.3 25.92l6.75-1a1.17 1.17 0 0 0 .88-.64l3-6.11a1.17 1.17 0 0 1 2.1 0l3 6.11a1.17 1.17 0 0 0 .88.64l6.75 1a1.17 1.17 0 0 1 .65 2l-4.88 4.76a1.17 1.17 0 0 0-.34 1l1.15 6.72a1.17 1.17 0 0 1-1.7 1.23l-6-3.17a1.17 1.17 0 0 0-1.09 0l-6 3.17a1.17 1.17 0 0 1-1.7-1.23l1.15-6.72a1.17 1.17 0 0 0-.34-1l-4.88-4.76a1.17 1.17 0 0 1 .62-2Zm-24.9-4h9.24v12.36c0 .6 0 1.2.06 1.79h-.06a9.25 9.25 0 0 1-9.24-9.24Z" />
  </svg>
)
export default LotsLeagues
