import { Box } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"
import style from './style.module.css';
import styled from "styled-components";
import HistoryIcon from '../../icons/history.jsx';
import PredictionsIcon from '../../icons/predictions.jsx';
import UserPicIcon from '../../icons/userpic.jsx';
import { emitEvent, EVENTS } from '../../models/statistics';
import { useUnit } from "effector-react";

const NavItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
`

const NavContainer = styled.div`
    padding: 8px 12px 24px;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: fixed;
    bottom: 0;
    background-color: var(--chakra-colors-chakra-subtle-bg);
    border-top: solid 1px #adadad66;
    box-shadow: 0px -2px 4px #0000001a;
    font-size: var(--font-small);
    z-index: 99999;
`
export const NavigationBar = () => {
    const [emitEventFn] = useUnit([emitEvent]);

    const handleNavClick = () => {
        emitEventFn(EVENTS.navigationClick);
    }

    return (
        <NavContainer>
            <NavLink className={({ isActive }) => (isActive ? style.activeLink : undefined)} to="/history" onClick={handleNavClick}>
                <NavItem>
                    <HistoryIcon className={style.icon} />
                    История
                </NavItem>
            </NavLink>
            <NavLink className={({ isActive }) => (isActive ? style.activeLink : undefined)} to="/" onClick={handleNavClick}>
                <NavItem>
                    <PredictionsIcon className={style.icon} />
                    Прогнозы
                </NavItem>
            </NavLink>
            <NavLink className={({ isActive }) => (isActive ? style.activeLink : undefined)} to="/profile" onClick={handleNavClick}>
                <NavItem>
                    <UserPicIcon className={style.icon} />
                    Профиль
                </NavItem>
            </NavLink>
        </NavContainer>
    )
}
