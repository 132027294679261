import { Box, Divider, Flex, Heading, Image, Spacer, Text } from "@chakra-ui/react"
import styled from "styled-components";
import { flags } from "../../flags";
import { CustomCard } from '../../components/Card';
import dayjs from "dayjs";

const BetLogo = styled.div`
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: white;
`

const Logo = ({ src }) => {
    return (
        <BetLogo>
            <Image
                src={src}
                height="25px"
            />
        </BetLogo>
    )
}

const getStatusColor = (status) => {
    switch (status) {
        case 'win':
            return 'green';
        case 'lose':
            return 'red';
        case 'rollback':
            return '#fcb603';
        default:
            return 'gray'
    }
}

const BetStatus = styled.div`
    background-color: ${props => getStatusColor(props.status)};
    color: white;
    border-radius: 6px;
    padding: 4px 8px;
    font-weight: 900;
    font-size: var(--font-small);
    width: 80px;
`


const BetTeamsHeader = styled.div`
    margin-left: 16px;
    font-size: 13px;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
`

const BetDescription = styled.div`
    font-size: var(--font-small);
    margin-top: 6px;
`;

export const BetCard = ({
    date,
    teamA,
    teamB,
    betDescription,
    betCoef,
    flag,
    status
}) => {
    return (
        <CustomCard>
            <Flex justifyContent="space-between" alignItems="center">
                <div>
                    <Flex alignItems="center">
                        <Logo src={flags[flag]} />
                        <BetTeamsHeader>
                            {teamA}
                            <br />
                            {teamB}
                        </BetTeamsHeader>
                    </Flex>
                    <BetDescription>{betDescription}</BetDescription>
                </div>
                <Box textAlign="center">
                    <BetStatus status={status}>{betCoef}</BetStatus>
                    <Box>
                        <Text mt="4px" fontWeight={600} fontSize={12}>{dayjs(date).format('DD.MM.YYYY')}</Text>
                    </Box>
                </Box>

            </Flex>
        </CustomCard>
    )
}