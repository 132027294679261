import { Box } from "@chakra-ui/react"

export const Page = ({ children }) => {
    return (
        <Box paddingX="12px" overflow="hidden" height="100%" maxHeight="100%">
            {children}
        </Box>
    )
}

export const ScrollablePageContent = ({ children, ...props}) => {
    return (
        <Box overflowY="auto" height="calc(100% - 160px)" {...props}>
            {children}
        </Box>
    )
}