import * as React from "react"
const NoAds = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 80"
    {...props}
  >
    <path
      fill="currentColor"
      d="M31.535 33.375V14.714a.73.73 0 0 1 .744-.714.73.73 0 0 1 .744.714v17.643a2 2 0 0 0 4 0V19.464a.73.73 0 0 1 .744-.714.73.73 0 0 1 .744.714v13.911a2 2 0 1 0 4 0v-9.16a.73.73 0 0 1 .745-.715.73.73 0 0 1 .744.714v17.643C44 45.23 41.23 48 37.767 48H27.291c-2.727 0-5.094-1.74-5.921-4.223l-.08-.244v-.001c-.155-.472-.353-1.078-.616-1.648L16.03 31.818a1.943 1.943 0 0 1-.027-.06.515.515 0 0 1 .523-.454c.614 0 1.496.455 2.74 1.644.575.55 1.134 1.162 1.708 1.795l.068.075c.53.584 1.1 1.212 1.643 1.718a2 2 0 0 0 3.362-1.465V17.43a.73.73 0 0 1 .744-.715.73.73 0 0 1 .745.715v15.946a2 2 0 1 0 4 0Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M41.864 2.004a6 6 0 0 1 4.247 1.748l14.075 14.012a6 6 0 0 1 1.766 4.24l.044 19.86a6 6 0 0 1-1.748 4.247L46.236 60.186a6 6 0 0 1-4.24 1.766l-19.86.044a6 6 0 0 1-4.247-1.748L3.814 46.236a6 6 0 0 1-1.766-4.24l-.044-19.86a6 6 0 0 1 1.748-4.247L17.764 3.814a6 6 0 0 1 4.24-1.766l19.86-.044ZM32.28 10a4.751 4.751 0 0 0-4.36 2.85 4.777 4.777 0 0 0-1.129-.136c-2.6 0-4.744 2.09-4.744 4.715v12.644l-.017-.016c-1.294-1.238-3.19-2.753-5.504-2.753-2.625 0-4.673 2.204-4.517 4.762.039.626.254 1.136.389 1.429l4.645 10.065c.157.34.271.687.426 1.157l.107.324C18.948 49.166 22.849 52 27.29 52h10.476C43.397 52 48 47.48 48 41.857V24.214c0-2.624-2.145-4.714-4.744-4.714-.253 0-.502.02-.745.058v-.094c0-2.624-2.144-4.714-4.744-4.714-.253 0-.501.02-.744.058v-.094c0-2.624-2.145-4.714-4.744-4.714Z"
      clipRule="evenodd"
    />
  </svg>
)
export default NoAds
