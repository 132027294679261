import { Flex, Modal, ModalContent, ModalOverlay, ModalCloseButton } from '@chakra-ui/react';
import { Premium } from './Premium';
import { useEffect, useRef } from 'react';
import { useUnit } from 'effector-react';
import { emitEvent, EVENTS } from '../../models/statistics';


export const PremiumModal = ({ isOpen, onClose }) => {
    const isEmitted = useRef(false);
    const [emit] = useUnit([emitEvent]);

    const handlePremiumClick = () => {
        emit(EVENTS.premiumPopupClick);
    }
    useEffect(() => {
        if (!isEmitted.current && isOpen) {
            emit(EVENTS.showPremiumPopup)
            isEmitted.current = true;
        }
    }, [isOpen, emit]);

    return (
        <Modal size="full" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <Flex height="100vh" flexDir='column' justifyContent='center'>
                    <Premium onPremium={handlePremiumClick}/>
                </Flex>
            </ModalContent>
        </Modal>
    )
}