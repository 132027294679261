import { Page, ScrollablePageContent } from "../../components/Page"
import { PageHeader } from "../../widgets/PageHeader"
import { Trends } from '../../components/Trends';
import { Predictions } from '../../components/Predictions';
import { Space } from "../../components/Space";
import { MatchContext } from '../../components/MatchContext';
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { config } from '../../config';
import { Flex, Spinner, Button, Heading, Box } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { Match } from './Match'


export const Details = () => {
    const { matchId } = useParams();
    const navigate = useNavigate();

    const { data: matchData, isLoading } = useQuery(["match", matchId], async () => {
        const response = await fetch(`${config.apiUrl}/match/${matchId}`)
        const data = await response.json()
        // setCurrentMatchData(data)
        return data;
    });

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <Page>
            <PageHeader />
            <Space />
            {isLoading && <Flex width="100%" height="100%" justifyContent="center" alignItems="center"> <Spinner /></Flex>}
            <ScrollablePageContent>
                <Flex alignItems="center" justifyContent="space-between" mb={4}>
                    <Button leftIcon={<ArrowBackIcon />} variant="ghost" onClick={handleGoBack} size="sm">
                        Назад
                    </Button>
                    <Heading as="h2" size="sm" textAlign="center" flex={1}>
                        Детали матча
                    </Heading>
                    <Box width={70} /> {/* Для баланса с кнопкой "Назад" */}
                </Flex>
                {matchData && (
                    <Box>
                        <Match
                            date={matchData.date}
                            teamA={matchData.teamA}
                            teamB={matchData.teamB}
                            league={matchData.league || 'Лига на задана'}
                            flag={matchData.flag}
                        />
                        {matchData.keyTrends.length > 0 && (
                            <>
                                <Space />
                                <Trends trends={matchData.keyTrends} />
                            </>
                        )}
                        <Space />
                        <Predictions bets={matchData.bets} />
                        <Space />
                        <MatchContext />
                    </Box>
                )}
            </ScrollablePageContent>
        </Page>
    )
}
