import { createStore } from "effector";
import { MAX_FREE_MATCHES } from './constants';

export const $tgUserData = createStore(null);
export const $userData = createStore({
    "_id": null,
    "openedMatches": [],
    "tryiedPremiumDate": null,
    "showedPremiumBannerDate": null,
    "isPremium": false
});
export const $userEmail = createStore("Гость");

export const $isUserPremium = $userData.map(userData => !!userData?.isPremium);
export const $isMatchesAvailable = $userData.map(userData => (userData?.openedMatches?.length || 0) < MAX_FREE_MATCHES);

// Новый стор для определения, является ли приложение Telegram-версией
export const $isTelegramApp = $tgUserData.map(tgData => !!tgData);

// Создаем стор $viewedMatches с просмотренными матчами из $userData
export const $viewedMatches = $userData.map(userData => userData?.openedMatches || []);
