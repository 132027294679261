import { createEffect, sample } from "effector";
import { $userData, $userEmail, $tgUserData } from './stores';
import { setTgUserData, initializeApp, matchShowed, setUserPremium, signIn, signUp } from './events';
import { checkUserFx, updateUserDataFx, getUserDataFx, saveUserDataFx, signInFx, signUpFx, initializeAppFx } from './effects';


$tgUserData.on(setTgUserData, (_, payload) => payload);

$userData
    .on(checkUserFx.doneData, (_, payload) => ({
        ...payload.user.userData
    }))
    .on(getUserDataFx.doneData, (_, payload) => payload)
    .on(updateUserDataFx.doneData, (_, payload) => payload)
    .on(saveUserDataFx.doneData, (_, payload) => payload);

$userEmail
    .on(checkUserFx.doneData, (_, payload) => payload.user.email)

sample({
    clock: initializeApp,
    target: initializeAppFx
});

sample({
    clock: checkUserFx.doneData,
    filter: (payload) => !!payload.userData?.id,
    fn: (payload) => payload.userData.id,
    target: getUserDataFx
});

sample({
    clock: setTgUserData,
    fn: (payload) => (payload.id),
    target: getUserDataFx
});

sample({
    clock: getUserDataFx.fail,
    target: createEffect(() => {
        localStorage.removeItem('userDataId');
        saveUserDataFx();
    })
})

sample({
    clock: matchShowed,
    source: $userData,
    filter: (userData, matchId) => !userData.openedMatches.includes(matchId),
    fn: (userData, matchId) => ({
        id: userData._id,
        ...userData,
        openedMatches: [...userData.openedMatches, matchId]
    }),
    target: [updateUserDataFx, $userData]
});

sample({
    clock: setUserPremium,
    source: $userData,
    fn: (userData) => ({
        id: userData._id,
        ...userData,
        isPremium: true,
        tryiedPremiumDate: new Date().toISOString()
    }),
    target: updateUserDataFx
});

sample({
    clock: checkUserFx.failData,
    filter: () => !!localStorage.getItem('token'),
    fn: () => {
        setTimeout(() => {
            window.location.href = '/signin';
        }, 100)
    }
});


sample({
    clock: signIn,
    target: signInFx
});

sample({
    clock: signUp,
    target: signUpFx
});

sample({
    clock: [signInFx.doneData, signUpFx.doneData],
    target: createEffect(() => {
        setTimeout(() => {
            checkUserFx()
        }, 500)
    })
});

getUserDataFx.failData.watch(() => {
    console.error('Ошибка при получении данных пользователя');
});

saveUserDataFx.failData.watch(() => {
    console.error('Ошибка при сохранении данных пользователя');
});

saveUserDataFx.doneData.watch((data) => {
    if (data && data._id) {
        localStorage.setItem('userDataId', data._id);
    }
});
