import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Heading,
    Container,
    useToast,
    Text,
} from '@chakra-ui/react';
import { useUnit } from 'effector-react';
import { signUp, signUpFx } from '../../models/user';

const signUpSuccessToastId = 'signUpSuccessToastId'
const signUpFailToastId = 'signUpFailToastId'

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const toast = useToast();
    const [handleSignUp, signUpPending] = useUnit([signUp, signUpFx.pending]);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSignUp({ email, password });
    };

    React.useEffect(() => {
        signUpFx.doneData.watch(() => {
            if (toast.isActive(signUpSuccessToastId)) {
                return;
            }
            toast({
                id: signUpSuccessToastId,
                title: "Регистрация успешна",
                description: "Вы успешно зарегистрировались и получили премиум статус.",
                status: "success",
                duration: 1000,
                isClosable: true,
                position: "top",
            });
            navigate('/');
        });

        signUpFx.fail.watch((error) => {
            if (toast.isActive(signUpFailToastId)) {
                return;
            }
            toast({
                id: signUpFailToastId,
                title: "Ошибка регистрации",
                description: error.error.response?.data?.message || "Произошла ошибка при регистрации",
                status: "error",
                duration: 1000,
                isClosable: true,
                position: "top",
            });
        });
    }, []);

    return (
        <Container maxW="container.sm" centerContent>
            <Box width="100%" p={4}>
                <VStack spacing={8} align="stretch">
                    <Heading as="h2" size="xl" textAlign="center">
                        Регистрация
                    </Heading>
                    <form onSubmit={handleSubmit}>
                        <VStack spacing={4}>
                            <FormControl isRequired>
                                <FormLabel htmlFor="email">Email:</FormLabel>
                                <Input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel htmlFor="password">Пароль:</FormLabel>
                                <Input
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </FormControl>
                            <Button type="submit" colorScheme="blue" width="100%" isLoading={signUpPending}>
                                Зарегистрироваться
                            </Button>
                        </VStack>
                    </form>
                    <Text textAlign="center">
                        Уже есть аккаунт? <Link to="/signin" style={{ color: 'blue' }}>Войти</Link>
                    </Text>
                </VStack>
            </Box>
        </Container>
    );
};

export default SignUp;
