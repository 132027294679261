import * as React from "react"
const InfinityIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 512 512"
    fill="currentColor"
    {...props}
  >
    <path d="M155.804 205.902c-27.625 0-50.098 22.473-50.098 50.098s22.473 50.098 50.098 50.098c28.788 0 52.979-23.076 77.68-50.098-24.702-27.022-48.891-50.098-77.68-50.098zM356.196 205.902c-28.788 0-52.979 23.076-77.68 50.098 24.7 27.022 48.891 50.098 77.68 50.098 27.625 0 50.098-22.473 50.098-50.098s-22.473-50.098-50.098-50.098z" />
    <path d="M256 0C114.844 0 0 114.839 0 256s114.844 256 256 256 256-114.839 256-256S397.156 0 256 0zm100.196 339.496c-41.465 0-71.896-27.919-100.196-58.632-28.3 30.713-58.73 58.632-100.196 58.632-46.042 0-83.496-37.454-83.496-83.496 0-46.042 37.454-83.496 83.496-83.496 41.465 0 71.896 27.919 100.196 58.632 28.3-30.713 58.73-58.632 100.196-58.632 46.042 0 83.496 37.454 83.496 83.496 0 46.042-37.454 83.496-83.496 83.496z" />
  </svg>
)
export default InfinityIcon
