import { BetsHistory } from "./BetsHistory"
import { Flex, Spinner } from "@chakra-ui/react";
import { useUnit } from "effector-react";
import { $historyData, $historyDataLoading } from "../../models/appState";

export const HistoryList = () => {
    const [bets, isLoading] = useUnit([
        $historyData,
        $historyDataLoading
    ])

    return (
        <>
            {isLoading && <Flex width="100%" height="100%" justifyContent="center" alignItems="center"> <Spinner /></Flex>}
            {bets && <BetsHistory bets={bets || []} />}
        </>
    )
}