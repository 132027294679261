import * as React from "react"
const Predictions = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-4.775 -10.056 80.252 80.179"
    {...props}
  >
    <path fill="currentColor" d="M35.385-9.993c-22.051 0-40 17.949-40 40s17.949 40 40 40 40-17.949 40-40-17.941-40-40-40Zm13.781 30.309L36.787 51.015a1.662 1.662 0 0 1-1.879 1.039c-.82-.141-1.379-.801-1.41-1.629L33.1 35.206a.878.878 0 0 0-.411-.719.93.93 0 0 0-.828-.09l-8.011 3.321c-.59.238-1.239.14-1.739-.262a1.652 1.652 0 0 1-.578-1.648l5.102-25.738a1.69 1.69 0 0 1 1.519-1.371l8.91-.762a1.68 1.68 0 0 1 1.481.629c.359.441.461 1.039.301 1.578l-3.102 9.961a.883.883 0 0 0 .231.91c.238.238.57.32.898.219l10.219-3.161c.629-.211 1.309-.019 1.762.481.441.488.57 1.172.32 1.781l-.008-.019Z" />
  </svg>
)
export default Predictions
