import { Box, Container } from "@chakra-ui/react"
import styled from "styled-components";
import { CustomCard } from "../../components/Card";
import { useUnit } from "effector-react";
import { $computedHistoryData } from "../../models/appState";
import MadePredictions from "../../icons/madePredictions";
import AvgCoef from '../../icons/avgCoef';
import SuccessPredictions from "../../icons/successPredictions";

const ResultValue = styled.div`
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ResultDescription = styled.div`
    font-size: var(--font-small);
`

const ResultRow = styled.div`
    display: flex;
    justify-content: space-between;
`

const ResultContainer = styled.div`
    text-align: center;
`

const StyledIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: inherit;
    
    & > svg {
        width: 100%;
        height: 100%;
    }
`;

export function Results() {
    const [data] = useUnit([$computedHistoryData]);
    console.log(data)
    return (
        <Box width="100%" zIndex={99999} boxSizing="border-box">
            <CustomCard>
                <Container boxSizing="border-box">
                    <ResultRow>
                        <ResultContainer>
                            <ResultValue>
                                <StyledIcon>
                                    <MadePredictions />
                                </StyledIcon>
                                {data.betsCount}
                            </ResultValue>
                            <ResultDescription>
                                Ставок сделано
                            </ResultDescription>
                        </ResultContainer>
                        <ResultContainer>
                            <ResultValue>
                                <StyledIcon>
                                    <SuccessPredictions />
                                </StyledIcon>
                                {Math.round(data.wonPersents * 100)}%
                            </ResultValue>
                            <ResultDescription>Ставок выиграно</ResultDescription>
                        </ResultContainer>
                        <ResultContainer>
                            <ResultValue>
                                <StyledIcon>
                                    <AvgCoef />
                                </StyledIcon>
                                {data?.avgCoef?.toFixed(2) ?? '-'}
                            </ResultValue>
                            <ResultDescription>Средний коэф.</ResultDescription>
                        </ResultContainer>
                    </ResultRow>
                </Container>
            </CustomCard>
        </Box>
    )
}