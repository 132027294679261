import { ChakraProvider, Box } from '@chakra-ui/react'

import './models'
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import theme from './theme';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { NavigationBar } from './widgets/NavigationBar/Navigation';
import { HomePage } from './pages/home/home';
import { ActiveMatches } from './pages/active-matches/active-matches';
import { motion } from 'framer-motion';
import { Details } from './pages/details/details';
import { ProfilePage } from './pages/profile/profile';
import { useEffect, useRef } from 'react';
import { useUnit } from 'effector-react';
import { $userData, initializeApp } from './models/user';
import { loadHistoryDataFx } from './models/appState';
import { SubscribeSuccess } from './pages/subscribe-success/subscribe-success';
import SignUp from './pages/sign-up/signup';
import SignIn from './pages/sign-in/sign-in';
import { Spinner } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import style from './App.module.css';

const queryClient = new QueryClient();


function App() {
  const initAppRef = useRef(false);
  const [initApp, loadHistory, userData] = useUnit([
    initializeApp,
    loadHistoryDataFx,
    $userData
  ]);

  const isLoading = !Boolean(userData._id);

  useEffect(() => {
    if (!initAppRef.current) {
      initApp();
      loadHistory();
      initAppRef.current = true;
    }
  }, [initApp, loadHistory]);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        {isLoading ? (
          <Flex width="100vw" height="100vh" justifyContent="center" alignItems="center">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <BrowserRouter>
            <AnimatedApp />
          </BrowserRouter>
        )}
      </ChakraProvider>
    </QueryClientProvider>
  );
}

const variants = {
  initial: {
    opacity: 0,
    x: -20,
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: 20,
  },
}

function AnimatedApp() {
  let { pathname } = useLocation();

  return (
    <Box boxSizing="border-box" height="calc(100vh - 87px)">
      <motion.div
        className={style.animationContainer}
        key={pathname}
        initial="initial"
        animate="in"
        variants={variants}
        transition={{ duration: 0.3, type: "easeInOut" }}
      >
        <Routes>
          <Route path='/' element={<ActiveMatches />} />
          <Route path='/history' element={<HomePage />} />
          <Route path='/details/:matchId' element={<Details />} />
          <Route path='/profile' element={<ProfilePage />} />
          <Route path='/subscribe' element={<Box>Subscribe popup</Box>} />
          <Route path='/subscribe-success' element={<SubscribeSuccess />} />
          <Route path='/details' element={<Box>Details</Box>} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/signin' element={<SignIn />} />
        </Routes>
      </motion.div>
      <NavigationBar />
    </Box>
  )
}
export default App;
