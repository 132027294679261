import { restore, createEvent, sample, createStore, createEffect } from 'effector'
import { config } from '../config';

const scrollChanged = createEvent();
const $scrollPosition = restore(scrollChanged, 0);

export const $isScrolled = createStore(false);

window.addEventListener('scroll', () => {
    scrollChanged(window.scrollY)
})

sample({
    source: $scrollPosition,
    clock: scrollChanged,
    fn: (scroll) => {
        return scroll !== 0;
    },
    target: $isScrolled
})

export const showInfoPopup = createEvent();

const showInfoPopupStorageValue = !(localStorage.getItem('showInfoPopup') === '0');

export const $showInfoPopup = restore(showInfoPopup, showInfoPopupStorageValue);

const setInfoPopupShownToLocalStorageFx = createEffect((show) => {
    if (!show) {
        localStorage.setItem('showInfoPopup', 0);
    }
})

sample({
    clock: showInfoPopup,
    target: [setInfoPopupShownToLocalStorageFx]
})

export const loadHistoryData = createEvent();

export const $historyData = restore(loadHistoryData, []);

export const loadHistoryDataFx = createEffect(async () => {
    const response = await fetch(`${config.apiUrl}/history-bets`)
    const data = await response.json()
    const bets = data.map(({ bet, match }) => {
        return {
            flag: match.flag,
            date: match.date,
            teamA: match.teamA,
            teamB: match.teamB,
            betDescription: bet.betDescription,
            betCoef: bet.betCoef,
            status: bet.status
        }
    })
    return bets
})

export const $historyDataLoading = loadHistoryDataFx.pending;

export const $computedHistoryData = createStore({
    betsCount: null,
    wonPersents: null,
    avgCoef: null
})

sample({
    clock: loadHistoryData,
    source: $historyData,
    fn: (data) => {
        return {
            betsCount: data.length,
            wonPersents: data.filter((bet) => bet.status === 'win').length / data.length,
            avgCoef: data.reduce((acc, bet) => acc + bet.betCoef, 0) / data.length
        }
    },
    target: $computedHistoryData
})


sample({
    clock: loadHistoryDataFx.doneData,
    target: loadHistoryData
})