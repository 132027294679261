import { Page } from "../../components/Page"
import { Box, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { setUserPremium } from '../../models/user';
import { useUnit } from "effector-react";

export const SubscribeSuccess = () => {
    const [onOpen] = useUnit([setUserPremium])
    const navigate = useNavigate();

    useEffect(() => {
        onOpen();
    }, [onOpen]);

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <Page>
            <Box padding="20px" width="100%" height="100vh" display='flex' flexDirection="column" justifyContent="center" alignItems="center">
                <Text textAlign="center" marginBottom="20px">
                    Благодарим за проявленный интерес! Платная версия скоро станет доступна.
                </Text>
                <Button onClick={handleGoBack}>
                    Вернуться назад
                </Button>
            </Box>
        </Page>
    )
}