import { Page, ScrollablePageContent } from "../../components/Page"
import { Space } from "../../components/Space"
import { HistoryList } from "../../widgets/HistoryList"
import { Legend } from "../../widgets/Legend"
import { PageHeader } from "../../widgets/PageHeader"


export const HomePage = () => {
    return (
        <Page>
            <PageHeader />
            <Legend />
            <Space />
            <ScrollablePageContent height="calc(100% - 180px)">
                <HistoryList />
            </ScrollablePageContent>
        </Page>
    )
}