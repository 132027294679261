import axios from 'axios';
import { config } from '../../config';

export const checkUser = async (token) => {
    const response = await axios.post(`${config.apiUrl}/checkUser`, { token }, {
        headers: { 'Content-Type': 'application/json' },    
    });
    return response.data;
};

export const updateUserData = async (userData) => {
    const response = await axios.post(`${config.apiUrl}/save-user-data`, { userData }, {
        headers: { 'Content-Type': 'application/json' }
    });
    return response.data;
};

export const getUserData = async (userDataId) => {
    const response = await axios.post(`${config.apiUrl}/user-data`, { id: userDataId }, {
        headers: { 'Content-Type': 'application/json' }
    });
    return response.data;
};

export const saveUserData = async () => {
    const response = await axios.post(`${config.apiUrl}/save-user-data`, {}, {
        headers: { 'Content-Type': 'application/json' }
    });
    return response.data;
};

export const signIn = async ({ email, password }) => {
    const response = await axios.post(`${config.apiUrl}/signin`, { email, password });
    return response.data;
};

export const signUp = async ({ email, password }) => {
    const response = await axios.post(`${config.apiUrl}/signup`, { email, password });
    return response.data;
};

