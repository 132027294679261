import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 380.44 380.50"
    {...props}
    fill="currentColor"
  >
    <path d="M190.21 0C295.2 0 380.44 85.23 380.44 190.23c0 105.04-85.18 190.22-190.23 190.22C85.21 380.45 0 295.24 0 190.23 0 85.19 85.17 0 190.21 0zm0 16.36c-96.04 0-173.85 77.83-173.85 173.86 0 96.07 77.78 173.85 173.85 173.85 96.03 0 173.86-77.81 173.86-173.85 0-96.08-77.78-173.86-173.86-173.86z" />
    <path d="M62.58 284.01c17.57-26.02 47.35-43.22 80.92-43.22h93.41c33.58 0 63.34 17.21 80.91 43.23-28.83 39.16-75.24 64.56-127.61 64.56-52.39 0-98.82-25.41-127.63-64.57z" />
    <path
      d="M190.2 54.52c43.25 0 78.32 35.07 78.32 78.32 0 43.25-35.07 78.32-78.32 78.32-43.25 0-78.32-35.07-78.32-78.32 0-43.25 35.07-78.32 78.32-78.32z"
      className="fil1"
    />
  </svg>
)
export default SvgComponent
