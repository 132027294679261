
import { styled } from 'styled-components';
import { Flex } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';


const LegendItem = styled.div`
    font-size: var(--font-small);
`;
const LegentContainer = styled.div`
    justify-content: space-around;
    text-align: center;
    gap: 4px;
    margin-top: 4px;
    display: flex;
`;

export const Legend = () => {
    return (
        <LegentContainer>
            <Flex alignItems="center">
                <Box marginRight={2} width="10px" height="10px" borderRadius={4} backgroundColor="green" />
                <LegendItem>Выиграно</LegendItem>
            </Flex>
            <Flex alignItems="center">
                <Box marginRight={2} width="10px" height="10px" borderRadius={4} backgroundColor="red" />
                <LegendItem>Проиграно</LegendItem>
            </Flex>
            <Flex alignItems="center">
                <Box marginRight={2} width="10px" height="10px" borderRadius={4} backgroundColor="#fcb603" />
                <LegendItem>Возврат</LegendItem>
            </Flex>
        </LegentContainer>
    )
}