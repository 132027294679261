import { createEffect, sample } from "effector";
import { checkUser, updateUserData, getUserData, saveUserData, signIn, signUp } from './requests';
import { setTgUserData } from "./events";
import { emitEvent, EVENTS } from '../statistics'; // Добавляем импорт

export const checkUserFx = createEffect(async () => {
    try {
        const token = localStorage.getItem('token');
        return await checkUser(token);
    } catch (error) {
        localStorage.removeItem('token'); // Удаляем токен в случае ошибки
        throw new Error('Ошибка при проверке пользователя');
    }
});

export const updateUserDataFx = createEffect(async (userData) => {
    try {
        return await updateUserData(userData);
    } catch (error) {
        throw new Error('Ошибка при обновлении данных пользователя');
    }
});

export const getUserDataFx = createEffect(async (userDataId) => {
    try {
        return await getUserData(userDataId);
    } catch (error) {
        throw new Error('Ошибка при получении данных пользователя');
    }
});

export const saveUserDataFx = createEffect(async () => {
    try {
        return await saveUserData();
    } catch (error) {
        throw new Error('Ошибка при сохранении данных пользователя');
    }
});

export const signInFx = createEffect(async ({ email, password }) => {
    const data = await signIn({ email, password });
    localStorage.setItem('token', data.token);
    emitEvent(EVENTS.successSignIn); // Добавляем отправку события
    return data;
});

export const signUpFx = createEffect(async ({ email, password }) => {
    const data = await signUp({ email, password });
    localStorage.setItem('token', data.token);
    emitEvent(EVENTS.successSignUp); // Добавляем отправку события
    return data;
});

export const initializeAppFx = createEffect(async () => {
    const token = localStorage.getItem("token");
    const userDataId = localStorage.getItem('userDataId');
    const q = new URLSearchParams(window.Telegram.WebApp.initData);
    const tgUserData = JSON.parse(q.get('user'));
    console.log({token, userDataId, tgUserData})
    if (tgUserData) {
        setTgUserData(tgUserData)
    } else if (token) {
        checkUserFx();
    } else if (userDataId) {
        getUserDataFx(userDataId);
    } else {
        saveUserDataFx();
    }
});
