import {useDisclosure, Button, Text } from "@chakra-ui/react"
import { CustomCard } from "./Card"
import { styled } from 'styled-components';
import Notebook from '../icons/ball';
import { PremiumModal } from '../widgets/Premium/PremiumModal';
import {useUnit } from 'effector-react';
import { $isUserPremium } from '../models/user';
import { emitEvent, EVENTS } from "../models/statistics";

const TrendsContainer = styled.div`
    font-size: var(--font-small);
    padding: 0 16px;
    position: relative;
`;

const Title = styled.h2`
    font-size: 12px;
    margin-bottom: 8px;
    color: var(--color-secondary);
    font-weight: 500;
    padding-left: 8px;
    padding-top: 4px;
    display: flex;

`;

const NotebookIconStyled = styled(Notebook)`
    width: 16px;
    height: 16px;
    padding-right: 6px;
`;

const ToPremiumButtom = styled.div`
    z-index: 99;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
`

const HideContent = styled.div`
    filter: blur(4px);
`

const DevelopmentMessage = styled(Text)`
    text-align: center;
    color: var(--color-secondary);
    font-style: italic;
`;

export const MatchContext = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isUserPremium, emitEventFn] = useUnit([$isUserPremium, emitEvent]);

    const handleOpen = () => {
        emitEventFn(EVENTS.premiumDetailsClick);
        onOpen();
    }
    return (
        <CustomCard title={<Title><NotebookIconStyled /> Анализ контекста матча</Title>}>
            <TrendsContainer>
                {isUserPremium ? (
                    <DevelopmentMessage>Функциональность в разработке</DevelopmentMessage>
                ) : (
                    <>
                        <ToPremiumButtom>
                            <Button onClick={handleOpen} colorScheme='blue' size='xs'>Доступно в Premium</Button>
                        </ToPremiumButtom>
                        <PremiumModal isOpen={isOpen} onClose={onClose} />
                        <HideContent>
                            Чтобы прочитать анализ контекста матча, необходимо подписаться на тариф "Премиум"
                            Это можно сделать купив подписку в разделе "Профиль"
                            Тариф "Премиум" Это позволит вам получить доступ к аналитике и прогнозам
                        </HideContent>
                    </>
                )}
            </TrendsContainer>
        </CustomCard>
    )
}
