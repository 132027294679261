import { CloseButton } from "@chakra-ui/react"
import { InfoIcon } from "@chakra-ui/icons"
import styled from "styled-components"

const PopupContainer = styled.div`
    padding: 8px 40px 8px 12px;
    font-size: var(--font-small);
    background-color: var(--chakra-colors-chakra-subtle-bg);
    border: solid 1px var(--color-secondary);
    border-radius: 12px;
    position: relative;
    line-height: 14px;
`;

const TextContainer = styled.div`
    display: flex;
    gap: 8px;
`;
export const InfoPopup = ({onClose}) => {
    return (
        <PopupContainer>
            <CloseButton onClick={onClose} color="var(--color-secondary)" position="absolute" right="8px" top="10px" size="sm" />
            <TextContainer>
                {/* <InfoIcon w={4} h={4} color="var(--color-secondary)" display="inline-block" marginTop="3px" /> */}
                Здесь представлен перечень матчей, рекомендованных программным обеспечением Wisebets для совершения ставки. Если Вы не можете найти интересующий Вас матч, значит система сочла его слишком рискованным, либо не удовлетворяющим строгие статистические критерии.
            </TextContainer>
        </PopupContainer>
    )
}