import { Text } from '@chakra-ui/react';
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    font-size: var(--font-small);
    font-weight: bold;
    align-items: center;
`
const TariffContainer = styled.div`
    background-color: ${props => props.bgColor};
    padding: 2px 6px;
    border-radius: 5px;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: var(--font-small);
    margin-left: 8px;
`;

export const Tariff = ({ type }) => {
    const isPremium = type === 'premium';

    const text = isPremium ? "PREMIUM" : "BASIC";

    const bgColor = isPremium ? "#fdca22" : "#00977C";

    return (
        <Container>
            <Text>Текущий тариф</Text>
            <TariffContainer bgColor={bgColor}>
                {text}
            </TariffContainer>
        </Container>
    )
}