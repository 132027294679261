import styled from 'styled-components';
import { CustomCard } from '../../components/Card'
import { Tariff } from '../../components/Tariff';
import UserPic from '../../icons/userpic.jsx';
import style from './style.module.css'
import { useUnit } from 'effector-react';
import { $tgUserData, $userData, $isUserPremium, $userEmail } from '../../models/user';
import { useNavigate } from 'react-router-dom';

const TariffDescription = styled.div`
    font-size: 10px;
`;

const UserTariff = styled.div`
    display: flex;
    align-items: end;
    flex-direction: column;
    gap: 2px;
`

const UserName = styled.span`
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const MAX_MATCHES_FOR_FREE = 3;

export const UserCard = () => {
    const navigate = useNavigate();
    const [tgUserData, appUserData, isUserPremium, userEmail] = useUnit([
        $tgUserData,
        $userData,
        $isUserPremium,
        $userEmail
    ])

    const matchesAvailible = Math.max(MAX_MATCHES_FOR_FREE - appUserData.openedMatches.length, 0)

    const displayName = tgUserData?.username || userEmail || "Гость"

    const handleClick = () => {
        navigate('/profile');
    }

    return (
        <CustomCard className={style.userCard} onClick={handleClick}>
            <div className={style.userInfo}>
                <UserPic className={style.userPic}></UserPic>
                <UserName>{displayName}</UserName>
            </div>
            <UserTariff>
                <Tariff type={isUserPremium ? "premium" : "base"}/>
                <TariffDescription>
                    {isUserPremium
                        ? "Премиум доступ"
                        : `Доступно бесплатных матчей: ${matchesAvailible}`}
                </TariffDescription>
            </UserTariff>
        </CustomCard>
    )
}
