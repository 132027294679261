import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Container,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useUnit } from 'effector-react';
import { signIn, signInFx } from '../../models/user';

const signInSuccessToastId = 'signInSuccessToastId'
const signInFailToastId = 'signInFailToastId'


const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const toast = useToast();
  const [handleSignIn, signInPending, signInFailed] = useUnit([signIn, signInFx.pending, signInFx.fail]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSignIn({ email, password });
  };

  React.useEffect(() => {

    signInFx.doneData.watch(() => {
      if (toast.isActive(signInSuccessToastId)) {
        return;
      }
      toast({
        id: signInSuccessToastId,
        title: "Вход выполнен успешно",
        description: "Вы успешно вошли в систему.",
        status: "success",
        duration: 1000,
        isClosable: true,
        position: "top",
      });
      navigate('/');
    });

    signInFx.fail.watch((error) => {
      if (toast.isActive(signInFailToastId)) {
        return
      }
      toast({
        id: signInFailToastId,
        title: "Ошибка входа",
        description: error.error.response?.data?.message || "Произошла ошибка при входе",
        status: "error",
        duration: 1000,
        isClosable: true,
        position: "top",
      });
    });
  }, []);

  return (
    <Container maxW="container.sm" centerContent>
      <Box width="100%" p={4}>
        <VStack spacing={8} align="stretch">
          <Heading as="h2" size="xl" textAlign="center">
            Вход
          </Heading>
          <form onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel htmlFor="email">Email:</FormLabel>
                <Input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="password">Пароль:</FormLabel>
                <Input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              <Button type="submit" colorScheme="blue" width="100%" isLoading={signInPending}>
                Войти
              </Button>
            </VStack>
          </form>
          <Text textAlign="center">
            Нет аккаунта? <Link to="/signup" style={{ color: 'blue' }}>Зарегистрироваться</Link>
          </Text>
        </VStack>
      </Box>
    </Container>
  );
};

export default SignIn;
