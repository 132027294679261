import { Page } from "../../components/Page"
import { Space } from "../../components/Space"
import { PageHeader } from "../../widgets/PageHeader"
import { InfoPopup } from '../../widgets/InfpPopup';
import { MatchesList } from "../../widgets/MatchesList/MatchesList";
import { useUnit } from "effector-react";
import { $showInfoPopup, showInfoPopup } from "../../models/appState";
import { useState } from "react";
import { AproveModal } from "../../components/AproveModal";
import { useDisclosure } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { $isUserPremium, $isMatchesAvailable, matchShowed, $viewedMatches } from "../../models/user";
import { PremiumModal } from "../../widgets/Premium/PremiumModal";
import { emitEvent, EVENTS } from "../../models/statistics";

export const ActiveMatches = () => {
    const [isMatchesAvailible, isUserPremium, emitMatchShowed, viewedMatches, emitEventFn] = useUnit([$isMatchesAvailable, $isUserPremium, matchShowed, $viewedMatches, emitEvent]);
    let navigate = useNavigate();
    const { isOpen: isAproveOpen, onClose: onAproveClose, onOpen: onAproveOpen } = useDisclosure();
    const { isOpen: isPremiumOpen, onClose: onPremiumClose, onOpen: onPremiumOpen } = useDisclosure();
    const [selectedMatchId, setSelectedMatchId] = useState(null);

    const [showPopup, changeShowState] = useUnit([
        $showInfoPopup,
        showInfoPopup
    ]);

    const handleContinue = () => {
        onAproveClose();
        redirectToSelectedMatch(selectedMatchId);
    }

    const redirectToSelectedMatch = (id) => {
        console.log('redirect', { id })
        emitMatchShowed(id);
        navigate(`/details/${id}`)
    }

    const onMatchClick = (id) => {
        emitEventFn(EVENTS.matchClick);
        setSelectedMatchId(id);

        if (isUserPremium || viewedMatches.includes(id)) {
            redirectToSelectedMatch(id);
        } else if (!isMatchesAvailible) {
            onPremiumOpen();
        } else {
            onAproveOpen();
        }
    }

    return (
        <Page>
            <PageHeader />
            {showPopup && <>
                <Space />
                <InfoPopup onClose={() => changeShowState(false)} />
            </>}
            <AproveModal isOpen={isAproveOpen} onContinue={handleContinue} onClose={onAproveClose} />
            <PremiumModal isOpen={isPremiumOpen} onClose={onPremiumClose} />
            <Space />
            <MatchesList onMatchClick={onMatchClick} />
        </Page>
    )
}
