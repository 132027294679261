import { Tab, TabList, Tabs, TabPanels, TabPanel, TabIndicator } from "@chakra-ui/react"
import { CustomCard } from "./Card"
import { styled } from 'styled-components';
import Ball from '../icons/ball';

const TrendsContainer = styled.div`
    font-size: var(--font-small);
    padding: 0 16px;
`;

const Title = styled.h2`
    font-size: 12px;
    margin-bottom: 8px;
    color: var(--color-secondary);
    font-weight: 500;
    padding-left: 8px;
    padding-top: 4px;
    display: flex;

`;

const BallIconStyled = styled(Ball)`
    width: 16px;
    height: 16px;
    padding-right: 6px;
`;

const Bet = ({coef, description, companies}) => {
    return (
        <p>
            {description} за {coef} в {companies}
        </p>
    )
}

export const Predictions = ({bets}) => {
    return (
        <CustomCard title={<Title><BallIconStyled /> Прогнозы</Title>}>
            <TrendsContainer>
                {bets.map((bet) => (
                    <Bet key={bet.description} coef={bet.betCoef} description={bet.betDescription} companies={bet.betCompanies}/>
                ))}
            </TrendsContainer>
        </CustomCard>
    )
}
