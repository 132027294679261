import deFlag from './de.svg'
import gbFlag from './gb.svg'
import esFlag from './es.svg'
import frFlag from './fr.svg'
import itFlag from './it.svg'
import euFlag from './eu.svg'
import clFlag from './cl.png'
import el from './el.png'
import ecl from './ecl.png'

export const flags = {
    DE: deFlag,
    GB: gbFlag,
    ES: esFlag,
    FR: frFlag,
    IT: itFlag,
    UEFA: euFlag,
    CL: clFlag,
    EL: el,
    ECL: ecl
}